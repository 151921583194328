/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { chromaPaletteParser } from '@components/Features/helper/chromaPaletteParser';
import { pushDataLayerForEvent } from '@lib/gtag';
import { redirectToHomeBase } from '@zoom-app/hooks/useRedirect';
import { colorPalette } from '@lib/constants';

const freeFormOptions = {
  exporting: { enabled: false },
  chart: {
    type: 'bar',
    style: {
      fontFamily: 'Montserrat',
      fontWeight: '500'
    },
    spacingBottom: 20,
    spacingTop: 24,
    spacingLeft: 150,
    spacingRight: 200
  },
  title: {
    text: ''
  },

  xAxis: {
    labels: {
      style: {
        fontSize: '0.875rem',
        whiteSpace: 'nowrap',
        width: 300,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },
  yAxis: {
    min: 0,
    max: 100,
    tickInterval: 10,
    allowDecimals: false,
    labels: {
      style: {
        fontSize: '1rem'
      },
      format: '{value}'
    },
    title: {
      text: '% of votes'
    }
  },
  plotOptions: {
    series: {
      borderRadius: 4
    }
  },
  series: [
    {
      showInLegend: false,
      name: '% of votes'
    }
  ],

  credits: {
    enabled: false
  },

  responsive: {
    rules: [{
      condition: {
        maxWidth: 200,
        maxHeight: 1024
      },
      chartOptions: {
        chart: {
          spacingBottom: 24,
          spacingTop: 48,
          spacingLeft: 119,
          spacingRight: 33
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal'
        }
      }
    }, {
      condition: {
        maxWidth: 1024,
        maxHeight: 1366
      },
      chartOptions: {
        chart: {
          spacingBottom: 24,
          spacingTop: 48,
          spacingLeft: 119,
          spacingRight: 33
        }
      }
    }]
  }
};

export const getPlatform = (platforms, platformName) => platforms
  .find((platform) => {
    if (platformName === 'twitch') {
      return (platform.name === platformName && platform.isExtension === false);
    }
    return platform.name === platformName;
  });

export const isPlatformAuthorized = (platforms, platformName) => !!getPlatform(platformName);

export const getOptionsForPoll = ({
  colorData,
  comments,
  highcharts
}) => {
  const chart = { ...freeFormOptions.chart, backgroundColor: colorData?.pollBgColor };
  const series = [{
    showInLegend: false,
    color: colorData?.pollChartColor,
    data: comments?.map((d) => d.weight),
    name: '% of votes'
  }];
  const tooltip = {
    formatter() {
      return `Total % of votes : ${this.y}%`;
    }
  };
  const yAxis = { ...freeFormOptions.yAxis, color: colorData?.pollAxisColor };
  const xAxis = {
    categories: comments?.map((d) => d.name),
    style: {
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
      width: 300,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };

  const { charts } = highcharts;
  if (charts && charts[charts.length - 1] && charts[charts.length - 1].series[0]) {
    const freeFormchart = charts[charts.length - 1];
    const pointsGraphics = freeFormchart?.series[0].points.map((point) => point?.graphic?.element);
    pointsGraphics.forEach((elem) => {
      elem?.setAttribute('id', `${elem?.point?.category}-id`);
    });
  }

  return {
    ...freeFormOptions, chart, series, yAxis, xAxis, tooltip
  };
};

export const getUpdatedOptions = (action, colorData) => {
  const generateCategoriesArray = (start) => {
    const array = [];
    for (let index = start; index <= 10; index += 1) {
      array.push(`option ${index.toString()}`);
    }
    return array;
  };
  const generateDataArray = (start) => new Array(11 - start).fill(0);

  const setColor = (dataNames) => (dataNames === 0 ? '#C4C4C4' : '#000000');

  let series; let xAxis; let yAxis; let
    chart;
  if (action && action.type === 'color') {
    chart = { ...freeFormOptions.chart, backgroundColor: action.colorData.pollBgColor };

    series = [{
      showInLegend: false,
      color: action.colorData.pollChartColor,
      name: '% of votes'
    }];
    yAxis = { ...freeFormOptions.yAxis, color: action.colorData.pollAxisColor };
    xAxis = {
      labels: {
        style: {
          fontSize: '0.875rem',
          whiteSpace: 'nowrap',
          width: 300,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: action.colorData.pollAxisColor
        }
      }
    };
    return {
      ...freeFormOptions, series, xAxis, yAxis, chart
    };
  }
  chart = { ...freeFormOptions.chart, backgroundColor: colorData?.pollBgColor };

  const totalWeight = action.data?.reduce((sum, obj) => {
    sum += obj.count;
    return sum;
  }, 0);

  action.data?.forEach((obj) => {
    obj.totalWeight = totalWeight;
    obj.weight = Number(((obj.count / totalWeight) * 100).toFixed(2));
  });

  series = [
    {
      showInLegend: false,
      color: colorData.pollChartColor,
      data: [...action.data.map((d) => d.weight), ...generateDataArray(action.data.length + 1)]
    }
  ];

  const tooltip = {
    formatter() {
      return `${Math.round((this.y / 100) * totalWeight)} vote(s) out of ${totalWeight}`;
    }
  };
  setColor(action.data.map((d) => d.name));
  yAxis = { ...freeFormOptions.yAxis, color: colorData.pollAxisColor };
  xAxis = {
    categories: [
      ...action.data.map((d) => d.name),
      ...generateCategoriesArray(action.data.length + 1)
    ],
    labels: {
      style: {
        color: colorData?.pollAxisColor
      }
    }
  };

  (action?.data?.length === 1) && pushDataLayerForEvent('populate_comments_power_poll');

  return {
    ...freeFormOptions, series, tooltip, xAxis, chart, yAxis
  };
};

export const reArrangeOptions = (options) => {
  if (options) {
    let index = options.findIndex((element) => element.isWinner);

    const newArr = [];

    while (newArr.length !== options.length) {
      newArr.push(options[index]);
      index = (index + 1) % options.length;
    }

    return newArr;
  }

  return [];
};

export const calcSliceWidth = (length) => {
  const wheelSize = 400;
  const wheelSliceSpacing = 50;
  const PI = 3.14159265358979;
  const sliceMaxWidth = PI * wheelSize + (wheelSize / 2);
  const sliceWidth = (sliceMaxWidth / length) - wheelSliceSpacing;
  return sliceWidth;
};

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const getFeatureSettings = (question) => {
  const { setting } = question;
  if (question.type === 'map' || question.type === 'wheel') {
    setting.CombineWords = false;
    setting.ProfanityFilter = false;
    setting.SplitWords = false;
    setting.Vertical = false;
    setting.showUserName = false;
    if (question.type === 'wheel') {
      setting.oneUoneV = false;
    }
  } else if (question.type === 'pollWithOptions' || question.type === 'freeForm') {
    setting.Vertical = false;
    setting.SplitWords = false;
  } else if (question.type === 'transientThought') {
    setting.CombineWords = false;
    setting.SplitWords = false;
    setting.Vertical = false;
    setting.CommentsOverlay = false;
  } else if (question.type === 'Quick Questions') {
    setting.CombineWords = false;
    setting.ProfanityFilter = false;
    setting.SplitWords = false;
    setting.Vertical = false;
    setting.CommentsOverlay = false;
  } else if (question.type === 'wordCloud') {
    setting.showUserName = false;
  } else if (question.type === 'newWheel') {
    setting.CombineWords = false;
    setting.ProfanityFilter = false;
    setting.SplitWords = false;
    setting.Vertical = false;
    setting.showUserName = false;
    setting.CommentsOverlay = false;
  } else if (question.type === 'talkingTiles') {
    setting.CombineWords = false;
    setting.SplitWords = false;
    setting.Vertical = false;
    setting.CommentsOverlay = false;
    setting.showUserName = true;
    setting.oneUoneV = false;
  }
  return setting;
};

export const getInteractionSettings = (interaction, userSetting) => {
  const setting = {
    SplitWords: false,
    Vertical: true,
    ProfanityFilter: true,
    oneUoneV: false,
    CombineWords: false,
    showUserName: true,
    CommentsOverlay: true,
    OptionsList: 'number',
    ttPlaybackSpeed: 'default',
    DefaultView: 'world',
    DisplayStyle: 'percentage',
    wheelShowUser: '1',
    LinkType: 'All',
    showLatestCommentsFirst: false,
    FontStyle: 'Sans-Serif',
    showResponses: true,
    enableNames: false,
    wheelMcqLimit: 'All',
  };

  if (interaction === 'freeForm') {
    setting.CombineWords = userSetting.pollCombineWords;
    setting.ProfanityFilter = userSetting.pollProfanityFilter;
    setting.oneUoneV = userSetting.pollOneUserOneVote;
    setting.CommentsOverlay = userSetting.pollShowOverlay;
    setting.DisplayStyle = userSetting.pollDisplayStyle;
    setting.FontStyle = userSetting.pollFontStyle;
  }
  if (interaction === 'pollWithOptions') {
    setting.CombineWords = userSetting.pollWithOptionsCombineWords;
    setting.ProfanityFilter = userSetting.pollWithOptionsProfanityFilter;
    setting.oneUoneV = userSetting.pollWithOptionsOneUserOneVote;
    setting.CommentsOverlay = userSetting.pollWithOptionsShowOverlay;
    setting.OptionsList = userSetting.pollWithOptionsList;
    setting.DisplayStyle = userSetting.pollWithOptionsDisplayStyle;
    setting.FontStyle = userSetting.pollWithOptionsFontStyle;
  }
  if (interaction === 'wordCloud') {
    setting.CombineWords = userSetting.wordCloudCombineWords;
    setting.ProfanityFilter = userSetting.wordCloudProfanityFilter;
    setting.oneUoneV = userSetting.wordCloudOneUserOneVote;
    setting.CommentsOverlay = userSetting.wordCloudShowOverlay;
    setting.Vertical = userSetting.wordCloudVertical;
    setting.SplitWords = userSetting.wordCloudSplitWords;
    setting.FontStyle = userSetting.wordCloudFontStyle;
  }
  if (interaction === 'transientThought') {
    setting.ProfanityFilter = userSetting.transientThoughtProfanityFilter;
    setting.oneUoneV = userSetting.transientThoughtOneUserOneVote;
    setting.ttPlaybackSpeed = userSetting.transientThoughtPlaybackSpeed;
    setting.transientThoughtDisplayStyle = userSetting?.transientThoughtDisplayStyle;
    setting.transientThoughtShowUsername = userSetting?.transientThoughtShowUsername;
    setting.showLatestCommentsFirst = userSetting?.transientThoughtShowLatestCommentsFirst;
    setting.FontStyle = userSetting.transientThoughtFontStyle;
  }
  if (interaction === 'wheel') {
    setting.CommentsOverlay = userSetting.wheelShowOverlay;
  }
  if (interaction === 'map') {
    setting.oneUoneV = userSetting.mapOneUserOneVote;
    setting.CommentsOverlay = userSetting.mapShowOverlay;
    setting.DefaultView = userSetting.mapDefaultView;
  }
  if (interaction === 'Quick Questions') {
    setting.showUserName = userSetting.quickQuestionShowUserName;
  }

  if (interaction === 'linkLibrary') {
    setting.LinkType = userSetting.linkLibraryLinkType;
  }

  if (interaction === 'newWheel') {
    setting.wheelShowUser = userSetting.wheelShowUser;
  }
  if (interaction === 'emojisEverywhere') {
    setting.oneUoneV = userSetting.emojisEverywhereOneUserOneVote;
    setting.CommentsOverlay = userSetting.emojisEverywhereShowOverlay;
  }
  if (interaction === 'textTrack') {
    setting.ProfanityFilter = userSetting.textTrackProfanityFilter;
    setting.Vertical = userSetting.textTrackVertical;
    setting.FontStyle = userSetting.textTrackFontStyle;
  }

  if (interaction === 'talkingTiles') {
    setting.ProfanityFilter = userSetting.talkingTilesProfanityFilter;
    setting.oneUoneV = userSetting.talkingTilesOneUserOneVote;
    setting.showUserName = userSetting?.talkingTilesShowUsername;
  }
  if (interaction === 'ratingPolls') {
    setting.CommentsOverlay = userSetting.ratingPollsShowOverlay;
    setting.DisplayStyle = userSetting.ratingPollsDisplayStyle;
  }

  if (interaction === 'fantasticFans') {
    setting.FontStyle = userSetting.fantasticFansFontStyle;
  }
  if (interaction === 'multipleChoice') {
    setting.FontStyle = userSetting.multipleChoiceFontStyle;
    setting.optionOrderType = userSetting.optionOrderType || 'number';
  }

  const unchangedUserSettings = {};
  for (const key in userSetting) {
    if (!Object.values(setting).includes(userSetting[key])) {
      unchangedUserSettings[key] = userSetting[key];
    }
  }

  const updatedSetting = { ...setting, ...unchangedUserSettings };

  return updatedSetting;
};

export const mapContinents = [
  { name: 'World', value: 'world' },
  { name: 'Asia', value: 'asia' },
  { name: 'Africa', value: 'africa' },
  { name: 'Australia', value: 'australia' },
  { name: 'Europe', value: 'europe' },
  { name: 'North America', value: 'north-america' },
  { name: 'South America', value: 'south-america' }
];

export const ensureSum100Percent = (processedCommentsPercentage) => {
  const percentages = processedCommentsPercentage.map((comment) => comment.weight);

  const total = percentages.reduce((acc, val) => acc + val, 0);

  if (total !== 100) {
    const largest = Math.max(...percentages);
    const index = percentages.indexOf(largest);
    if (total > 100) {
      processedCommentsPercentage[index].weight -= (total - 100);
      return processedCommentsPercentage;
    }
    processedCommentsPercentage[index].weight += 100 - total;
    return processedCommentsPercentage;
  }
  return processedCommentsPercentage;
};

export const handleKeyStrokes = (e) => {
  const { key } = e;
  if (key.match(/(Enter|Space)/)) {
    e.preventDefault();
    document.activeElement.click();
  }
  if (key.match(/(ArrowRight|ArrowDown)/)) {
    e.preventDefault();
    e.currentTarget.nextSibling && e.currentTarget.nextSibling.focus();
  }
  if (key.match(/(ArrowLeft|ArrowUp)/)) {
    e.preventDefault();
    e.currentTarget.previousSibling && e.currentTarget.previousSibling.focus();
  }
};

export const prepareROSPayload = (
  template,
  questions,
  userId,
  selectedGPTQuestions = [],
  fresh = false
) => {
  const payload = {
    ...template,
    userId,
    selectedGPTQuestions
  };

  if (fresh) {
    delete payload.id;
  }
  const processedQuestions = questions.map((question, idx) => {
    const cleanedOptions = question?.options
      ?.filter(({ body }) => body.trim().length > 0)
      ?.map((item, index) => ({ ...item, option_number: index + 1 }));

    const { id, ...rest } = question;
    const cleanedQuestion = {
      ...rest,
      userId,
      questionNumber: idx + 1,
      question_number: idx + 1,
      options: cleanedOptions,
    };

    return fresh ? cleanedQuestion : { id, ...cleanedQuestion };
  });

  return { ...payload, questions: processedQuestions };
};

export const fontOptions = ['Sans-Serif', 'Oswald', 'Poppins', 'Merriweather', 'Ultra', 'Lobster', 'Philosopher', 'Flama', 'Helvetica Neue'];

export const configureFont = (settings) => {
  const fontConfig = {};

  const fontFamilies = settings.FontStyle === 'Sans-Serif' ? ['Open+Sans'] : [settings.FontStyle];

  if (['Flama'].includes(settings.FontStyle)) {
    fontConfig.custom = { families: fontFamilies, urls: ['../../styles/flama.css'] };
  } else if (['Helvetica Nueue'].includes(settings.FontStyle)) {
    fontConfig.custom = { families: fontFamilies, urls: ['../../styles/helvetica-nueue.css'] };
  } else {
    fontConfig.google = { families: fontFamilies };
  }

  return fontConfig;
};

export const handleCountAndRedirect = (router) => {
  const queryParams = new URLSearchParams(router.query);
  let count = parseInt(queryParams.get('count'), 10) || 0;
  count += 1;
  queryParams.set('count', count.toString());
  if (count > 3) {
    localStorage.setItem('error', true);
    redirectToHomeBase(router);
  } else {
    router.push({
      pathname: router.pathname,
      search: queryParams.toString()
    });
  }
};

export const previousDefaultTemplates = [
  'Product Marketing or Sales Webinar [SAMPLE]',
  'Company Town Hall / All Hands [SAMPLE]',
  'Cohort Based Class / Training / Coaching [SAMPLE]',
  'AMA / Just Chatting Livestream  [SAMPLE]',
  'Educational Livestream [SAMPLE]',
  'Gaming / Watchalong Livestream [SAMPLE]'
];

export const getSlideUrl = ({ interaction, slide, isLargePreview = false }) => {
  const statusToImageMap = {
    pending: '/images/dummy-image.png',
    failed: '/images/failed-image.png'
  };

  if (interaction && interaction.type === 'PDF Slide') {
    const getSlideKey = () => (isLargePreview
      ? interaction?.presentation_slide_s3_key
      : interaction?.slide?.s3_key_medium || interaction?.presentation_slide_s3_key);

    const slideKey = getSlideKey();
    const slideStatus = interaction?.slide?.status;

    return statusToImageMap[slideStatus] || `${process.env.NEXT_PUBLIC_BUCKET_URL}${slideKey}`;
  }
  if (slide) {
    const slideStatus = slide?.status;
    return statusToImageMap[slideStatus] || slide?.url;
  }

  return '/images/dummy-image.png';
};

export const getMaxCommenters = (currentTeam, platformType) => {
  let maxCommenters = null;

  const { audienceSize: defaultAudienceSize } = currentTeam.billing_account.purchase_plan.settings ?? {};

  const { audienceSize, bufferSize } = currentTeam.billing_account.settings ?? {};

  maxCommenters = Math.max(audienceSize || 0, defaultAudienceSize);

  if (bufferSize) {
    maxCommenters += (maxCommenters * (bufferSize / 100));
  }

  return maxCommenters;
};

export const getMaxTimeLimit = (currentTeam) => currentTeam.billing_account.purchase_plan.settings.youtubeMaxTimeLimit;

export const platformName = {
  all: 'All',
  hybrid: 'StreamAlive',
  'other-online-events': 'StreamAlive',
  ipa: 'StreamAlive',
  teams: 'Microsoft Teams',
  youtube: 'YouTube',
  zoom: 'Zoom',
  meet: 'Google Meet',
  twitch: 'Twitch',
  vimeo: 'Vimeo',
  webex: 'Webex',
  airmeet: 'Airmeet',
  streamyard: 'Streamyard',
  kick: 'Kick',
  restream: 'Restream',
  linkedin: 'LinkedIn'
};

export const interactionsName = {
  map: {
    name: 'Magic Maps'
  },
  pollWithOptions: {
    name: 'Power Polls'
  },
  freeForm: {
    name: 'Open-Ended Polls'
  },
  wordCloud: {
    name: 'Wonder Words'
  },
  emojisEverywhere: {
    name: 'Emojis Everywhere'
  },
  transientThought: {
    name: 'Transient Thoughts'
  },
  talkingTiles: {
    name: 'Talking Tiles'
  },
  wheel: {
    name: 'Choice Circle'
  },
  newWheel: {
    name: 'Winning Wheel'
  },
  ImpromptuWheel: {
    name: 'Winning Wheel'
  },
  ratingPolls: {
    name: 'Rating Polls'
  },
  multipleChoice: {
    name: 'Multiple Choice'
  },
  'Quick Questions': {
    name: 'Quick Questions'
  },
  linkLibrary: {
    name: 'Link Library'
  },
  textTrack: {
    name: 'Text Track'
  },
  fantasticFans: {
    name: 'Fantastic Fans'
  }
};

export const getColor = (colors, index, chromaColor) => {
  let palette = chromaPaletteParser().parse(colorPalette, chromaColor);

  if (colors?.ttmultipleColors) {
    return palette[index % palette.length];
  }
  return index % 2 ? colors.chatBubble1 : colors.chatBubble2;
};

export const getTextColor = (colors, index) => {
  if (colors?.ttmultipleColors) {
    return '#000000';
  }
  return index % 2 ? colors.ttTextColor1 : colors.ttTextColor2;
};

export const getText = (platformType, hybridPlatform) => {
  if (['zoom', 'meet', 'teams', 'ipa', 'other-online-events', 'webex'].includes(platformType) || ['Zoom', 'Google Meet', 'Microsoft Teams'].includes(hybridPlatform)) {
    return 'meeting';
  } else if (['vimeo', 'linkedin', 'airmeet'].includes(platformType)) {
    return 'event';
  }
  return 'livestream';
};

export const platformImages = [
  { name: 'Zoom', src: '/images/zoom-chat.png', className: 'h-15' },
  { name: 'Google Meet', src: '/images/google-meet-chat.png', className: 'h-15' },
  { name: 'Teams', src: '/images/teams-chat.png', className: 'h-16' },
];
